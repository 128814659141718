import React from 'react'

import './qr-code.styles.scss'

const Figure = props => {
    return (
        <figure
            className={
                'QR-Code__homepage ' + (props.className ? props.className : '')
            }
        >
            <div className="inner-container">
                <img
                    className="menu-img"
                    src="https://d2jotzgct8q460.cloudfront.net/imgs/projects/bbot-marketing/my-venue.svg"
                    alt="Phone Menu Hero"
                />
                <div className="click-me-container" />
            </div>
            <div className="pulse-container stagger-pulse">
                <div className="pulse-2"></div>
                <div className="pulse-1"></div>
            </div>
        </figure>
    )
}

export default Figure
