import React from 'react'

import './phone-menu.styles.scss'

const Figure = props => {
    return (
        <figure
            className={
                'Phone__menu ' + (props.className ? props.className : '')
            }
        >
            <div className="phone-screen">
                <img
                    className="menu-img"
                    src="https://d2jotzgct8q460.cloudfront.net/imgs/projects/bbot-marketing/phone-menu.svg"
                    alt="Phone Menu Hero"
                />
            </div>
            {props.QrCode && props.QrCode}
        </figure>
    )
}

export default Figure
