import React from 'react'
import { Link } from 'gatsby'

// Components
import Layout from '../../components/layout.js'
import SEO from '../../components/seo.js'

// Figures
import FigureBbotPhone from '../../figures/phone/phone-menu'
import FigureQrCode from '../../figures/qr-code/qr-code'

// SVGs

export default () => (
    <Layout className="projects-page">
        <SEO
            title="Projects"
            description="Learn more about what kind of things I'm working on!"
        />
        <section className="padding-top-10 padding-bottom-6 hero position-relative">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 offset-lg-1 col-md-4 offset-md-4 col-6 offset-3 margin-bottom-3">
                        <img
                            className="hero-svg"
                            src={
                                'https://d2jotzgct8q460.cloudfront.net/svgs/peep-sitting-11.svg'
                            }
                            alt="Peep Sitting"
                        />
                    </div>
                    <div className="col-lg-7 offset-lg-1 col-md-12 align-self-center">
                        <h2 className="margin-bottom-3">
                            Here are some of the things I've been up to!{' '}
                            <span role="img" aria-label="computer-emoji">
                                👨‍💻
                            </span>
                        </h2>
                        <h3 className="weight-500 margin-bottom-3">
                            I like to keep busy and don't mind getting my hands
                            dirty.
                        </h3>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="cta-row">
                                    <Link
                                        to="/about"
                                        className="secondary-button margin-right-3 margin-bottom-1"
                                    >
                                        About Me
                                    </Link>
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://d2jotzgct8q460.cloudfront.net/files/Resume_Ian_Banatoski.pdf"
                                        className="primary-button primary-button__red hover margin-right-3 margin-bottom-1"
                                    >
                                        Resume
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="padding-y-6 position-relative">
            <div className="container">
                <div className="row">
                    {/* <div className="col-md-6 margin-bottom-3">
                        <Link
                            to="/projects/bbot-marketing-website"
                            className="card bbot-marketing"
                        >
                            <h4 className="margin-bottom-2">
                                <span className="highlight-text">Website</span>
                            </h4>
                            <h3 className="margin-bottom-4">
                                <span className="highlight-text">
                                    Bbot Marketing Website &amp; Rebranding
                                </span>
                            </h3>
                            <p className="weight-600">
                                <span className="highlight-text">
                                    Bbot Inc.
                                </span>
                            </p>

                            <div className="img-container d-flex justify-content-center remain-visible">
                                <FigureBbotPhone QrCode={<FigureQrCode />} />
                            </div>
                            <div className="bg-img"></div>
                            <div className="tag-container">
                                <div className="tag margin-bottom-1 margin-right-1">
                                    <div className="content">AWS</div>
                                </div>
                                <div className="tag margin-bottom-1 margin-right-1">
                                    <div className="content">Gatsby</div>
                                </div>
                                <div className="tag margin-bottom-1 margin-right-1">
                                    <div className="content">Contentful</div>
                                </div>
                                <div className="tag margin-bottom-1 margin-right-1">
                                    <div className="content">GraphQL</div>
                                </div>
                            </div>
                        </Link>
                    </div> */}
                    <div className="col-md-6 margin-bottom-3">
                        <Link
                            to="/projects/rally-for-restaurants"
                            className="card r4r"
                        >
                            <h4 className="margin-bottom-2">
                                <span className="highlight-text">
                                    Web Application
                                </span>
                            </h4>
                            <h3 className="margin-bottom-4">
                                <span className="highlight-text">
                                    Rally for Restaurants
                                </span>
                            </h3>
                            <p className="weight-600">
                                <span className="highlight-text">
                                    Toast Inc.
                                </span>
                            </p>
                            <div className="img-container d-flex justify-content-center">
                                <img
                                    className="set-width"
                                    src={
                                        'https://d2jotzgct8q460.cloudfront.net/svgs/Rally-For-Restaurants.svg'
                                    }
                                    alt="Rally for Restaurants Logo"
                                />
                            </div>
                            <img
                                className="bg-img"
                                src={
                                    'https://d2jotzgct8q460.cloudfront.net/imgs/r4r-screenshot.jpg'
                                }
                                alt="asdasd"
                            />
                            <div className="tag-container">
                                <div className="tag margin-bottom-1 margin-right-1">
                                    <div className="content">AWS</div>
                                </div>
                                <div className="tag margin-bottom-1 margin-right-1">
                                    <div className="content">Leaflet</div>
                                </div>
                                <div className="tag margin-bottom-1 margin-right-1">
                                    <div className="content">Algolia</div>
                                </div>
                                <div className="tag margin-bottom-1 margin-right-1">
                                    <div className="content">Javascript</div>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-md-12 margin-bottom-3">
                        <Link
                            to="/projects/toast-ecommerce"
                            className="card toast"
                        >
                            <div className="row">
                                <div className="col-md-4 align-self-center">
                                    <div className="img-container d-flex justify-content-center align-items-center">
                                        <img
                                            src={
                                                'https://d2jotzgct8q460.cloudfront.net/imgs/Flex-Bundle.png'
                                            }
                                            alt="Toast Flex Bundle"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <h4 className="margin-bottom-2">
                                        <span className="highlight-text">
                                            Ecommerce
                                        </span>
                                    </h4>
                                    <h3 className="margin-bottom-4">
                                        <span className="highlight-text">
                                            Understanding the Demand for buying
                                            Toast Online
                                        </span>
                                    </h3>
                                    <p className="weight-600 margin-bottom-10">
                                        <span className="highlight-text">
                                            Toast Inc.
                                        </span>
                                    </p>
                                    <div className="tag-container">
                                        <div className="tag margin-bottom-1 margin-right-1">
                                            <div className="content">HTML</div>
                                        </div>
                                        <div className="tag margin-bottom-1 margin-right-1">
                                            <div className="content">
                                                Javascript
                                            </div>
                                        </div>
                                        <div className="tag margin-bottom-1 margin-right-1">
                                            <div className="content">
                                                Marketo
                                            </div>
                                        </div>
                                        <div className="tag margin-bottom-1 margin-right-1">
                                            <div className="content">
                                                Craft CMS
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-filter-layer"></div>
                            <img
                                className="bg-img bg-top"
                                src={
                                    'https://d2jotzgct8q460.cloudfront.net/imgs/Toast-Ecomm.jpg'
                                }
                                alt="Toast Ecomm"
                            />
                        </Link>
                    </div>
                    <div className="col-md-6 margin-bottom-3">
                        <Link
                            to="/projects/toast-success-report"
                            className="card toast"
                        >
                            <h4 className="margin-bottom-2">
                                <span className="highlight-text">
                                    Data Visualization
                                </span>
                            </h4>
                            <h3 className="margin-bottom-4">
                                <span className="highlight-text">
                                    Industry Success Report
                                </span>
                            </h3>
                            <p className="weight-600">
                                <span className="highlight-text">
                                    Toast Inc.
                                </span>
                            </p>
                            <div className="img-container d-flex justify-content-center align-items-center">
                                <img
                                    className="set-width"
                                    src={
                                        'https://d2jotzgct8q460.cloudfront.net/svgs/Toast-Logo.svg'
                                    }
                                    alt="Toast Logo"
                                />
                            </div>
                            <div className="tag-container">
                                <div className="tag margin-bottom-1 margin-right-1">
                                    <div className="content">React</div>
                                </div>
                                <div className="tag margin-bottom-1 margin-right-1">
                                    <div className="content">D3 JS</div>
                                </div>
                                <div className="tag margin-bottom-1 margin-right-1">
                                    <div className="content">Node</div>
                                </div>
                                <div className="tag margin-bottom-1 margin-right-1">
                                    <div className="content">Python</div>
                                </div>
                            </div>
                            <img
                                className="bg-img"
                                src={
                                    'https://d2jotzgct8q460.cloudfront.net/imgs/success-report.jpg'
                                }
                                alt="Success Report BG"
                            />
                        </Link>
                    </div>
                    <div className="col-md-6 margin-bottom-3">
                        <Link
                            to="/projects/beautiful-clean-coal"
                            className="card coal"
                        >
                            <h4 className="margin-bottom-2">
                                <span className="highlight-text">
                                    Data Visualization
                                </span>
                            </h4>
                            <h3 className="margin-bottom-4">
                                <span className="highlight-text">
                                    Beautiful Clean Coal
                                </span>
                            </h3>
                            <p className="weight-600">
                                <span className="highlight-text">
                                    WPI Project
                                </span>
                            </p>
                            <div className="img-container d-flex justify-content-center">
                                <img
                                    className="feature-img"
                                    src={
                                        'https://d2jotzgct8q460.cloudfront.net/imgs/tree-clouds.png'
                                    }
                                    alt="Tree Clouds"
                                />
                            </div>
                            <div className="tag-container">
                                <div className="tag margin-bottom-1 margin-right-1">
                                    <div className="content">React JS</div>
                                </div>
                                <div className="tag margin-bottom-1 margin-right-1">
                                    <div className="content">Semiotic</div>
                                </div>
                            </div>
                            <img
                                className="bg-img"
                                src={
                                    'https://d2jotzgct8q460.cloudfront.net/imgs/power-plant2.png'
                                }
                                alt="Power Plant"
                            />
                        </Link>
                    </div>
                    <div className="col-md-6 margin-bottom-3">
                        <Link
                            to="/projects/qualifying-questions"
                            className="card qq"
                        >
                            <h4 className="margin-bottom-2">
                                <span className="highlight-text">
                                    Lead Capture Application
                                </span>
                            </h4>
                            <h3 className="margin-bottom-4">
                                <span className="highlight-text">
                                    Qualifying Questions
                                </span>
                            </h3>
                            <p className="weight-600">
                                <span className="highlight-text">
                                    Toast Inc.
                                </span>
                            </p>
                            <div className="img-container d-flex justify-content-center">
                                <img
                                    className="feature-img"
                                    src={
                                        'https://d2jotzgct8q460.cloudfront.net/imgs/projects/qq-form/QQ-New-Restaurant.svg'
                                    }
                                    alt="QQ New Restaurant"
                                />
                            </div>
                            <div className="tag-container">
                                <div className="tag margin-bottom-1 margin-right-1">
                                    <div className="content">React JS</div>
                                </div>
                                <div className="tag margin-bottom-1 margin-right-1">
                                    <div className="content">
                                        Data Structures
                                    </div>
                                </div>
                            </div>
                            <img
                                className="bg-img"
                                src={
                                    'https://d2jotzgct8q460.cloudfront.net/imgs/projects/qq-form/QQ-Flow-Chart.png'
                                }
                                alt="QQ Flow Chart"
                            />
                        </Link>
                    </div>
                    <div className="col-md-6 margin-bottom-3">
                        <div className="row">
                            <div className="col-md-12">
                                <Link to="/about" className="card else">
                                    <div className="row">
                                        <div className="col-5">
                                            <img
                                                src={
                                                    'https://d2jotzgct8q460.cloudfront.net/svgs/peep-mustache.svg'
                                                }
                                                alt="Peep 32"
                                            />
                                        </div>
                                        <div className="col-7">
                                            <h3 className="center">
                                                <span className="highlight-text">
                                                    Who's Ian?
                                                </span>
                                            </h3>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Layout>
)
